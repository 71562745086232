<template>
  <a-button @click="doClick">
    <slot :name="key" v-for="(item, key) in $slots"></slot>
  </a-button>
</template>
<script setup>
import { defineProps, defineEmits, useSlots, createVNode } from "vue";
import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
const slots = useSlots();
const props = defineProps({
  msg: {
    type: String,
    default: "此操作比较重要,确认要继续吗!",
  },
  click: {
    type: Function,
  },
});
const emits = defineEmits(["click"]);

const handler = (event, ...args) => {
  if (props.click && props.click instanceof Function) {
    return Promise.resolve(props.click(event, ...args));
  } else {
    console.warn(
      "建议传入 click 响应函数,以支持异步关闭此对话框! 提示: @click为绑定事件, :click为传入响应函数!"
    );
    emits("click", event, ...args);
    return Promise.resolve(true);
  }
};

const doClick = (event, ...args) => {
  Modal.confirm({
    title: ["当前操作: ", ...Object.values(slots).map((item) => item())],
    icon: createVNode(ExclamationCircleOutlined),
    content: props.msg,
    async onOk() {
      try {
        return await handler(event, ...args);
      } catch (e) {
        return console.error(e);
      }
    },
    onCancel() {},
  });
};
</script>
