<template>
  <a-table :columns="columnsConst" @resizeColumn="resizeColumn">
    <template
      v-for="(item, key, index) in $slots"
      :key="index"
      v-slot:[key]="slotScope"
    >
      <slot :name="key" v-bind="slotScope"></slot>
    </template>
  </a-table>
</template>
<script setup>
import { defineProps, toRefs, reactive, h, ref } from "vue";
import { SettingOutlined } from "@ant-design/icons-vue";
import { Modal, Table } from "ant-design-vue";
import { useRoute } from "vue-router";
const route = useRoute();
const resizeColumn = (w, col) => {
  col.width = w;
  return false;
};

const props = defineProps({
  columns: {
    type: Array,
    required: true,
  },
  storageKey: {
    type: String,
    default: undefined,
  },
  showSetting: {
    type: Boolean,
    default: true,
  },
});

const STORAGE_KEY = props.storageKey || route.fullPath;

const { columns: columnsConst } = toRefs(props);

columnsConst.value.forEach((item, index) => {
  if (item.width && typeof item.width === "string") {
    item.resizable = false;
    // item.ellipsis = true; // 部分表格里有下拉菜单时,出现问题,不能使用此属性
  } else {
    item.resizable = true;
    // item.ellipsis = true;
    item.width = item.width ? item.width : 80;
  }
  item.responsive = false;
  item.lastCol = index === columnsConst.value.length - 1;
  if (item.lastCol && !item.oldTitile && props.showSetting) {
    item.oldTitile = item.title;
    item.title = h(
      "div",
      {
        style: {
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          flexWrap: "nowrap",
          justifyContent: "space-between",
          alignItems: "center",
        },
      },
      [
        item.oldTitile,
        h(
          "a",
          {
            type: "text",
            onClick: () => {
              settingModal();
              return false;
            },
            title: "表格设置",
          },
          h(SettingOutlined)
        ),
      ]
    );
    item.fixed = "right";
  }
});
let isEnd = true;
for (let i = columnsConst.value.length - 1; i > 0; i--) {
  if (!columnsConst.value[i].width) {
    if (isEnd) {
      isEnd = false;
      continue;
    } else {
      columnsConst.value[i].width = 100;
    }
  }
}

const columnNamesDefault = [...columnsConst.value]
  .filter((item) => !item.lastCol)
  .map((item) => item.title);

const dataSource = columnNamesDefault.map((value) => ({ value }));

const initLocalSelectedKey = () => {
  const localValue = localStorage.getItem(STORAGE_KEY);
  if (localValue) {
    try {
      return JSON.parse(localValue);
    } catch (e) {
      return [];
    }
  } else {
    return [];
  }
};

const changeHandler = (val) => {
  if (!(val && val.length)) {
    localStorage.removeItem(STORAGE_KEY);
    columnsConst.value.forEach((item) => (item.responsive = false));
  } else {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(val));
    columnsConst.value.forEach((item) => {
      const responsive = val.includes(item.title);
      item.responsive = responsive ? [] : false;
    });
  }
};

changeHandler(initLocalSelectedKey());

const localSelectedKey = ref(initLocalSelectedKey());

const modalState = reactive({
  selectedRowKeys: [...localSelectedKey.value],
  onChange: (selectedRowKeys) => {
    modalState.selectedRowKeys = selectedRowKeys;
  },
});
const settingModal = () => {
  const modal = Modal.confirm();
  const columns = [
    {
      title: "列名",
      dataIndex: "value",
      ellipsis: true,
    },
  ];
  modal.update({
    autoFocusButton: null,
    content: h(Table, {
      columns,
      dataSource,
      size: "small",
      pagination: false,
      rowKey: "value",
      rowSelection: modalState,
      scroll: {
        y: "35vh",
      },
      style: {
        width: "100%",
      },
    }),
    icon: null,
    keyboard: true,
    mask: true,
    maskClosable: true,
    title: "隐藏表格列",
    okText: "确定",
    cancelText: "取消",
    closable: true,
    onCancel: () => {
      modalState.selectedRowKeys = [...localSelectedKey.value];
    },
    onOk: () => {
      const value = [...modalState.selectedRowKeys];
      localSelectedKey.value = value;
      changeHandler(value);
    },
  });
};
</script>
