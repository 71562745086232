/**
 * @Auther: 杨鹏飞
 * @Date: 2022/8/26 -08 - 26 -5:19 下午
 * @Description: 公共路由
 */

export default [
  {
    path: "/",
    redirect: "/login",
  },
];
