<template>
  <a-config-provider :locale="zhCN" component-size="default" prefixCls="dark">
    <router-view />
  </a-config-provider>
</template>
<script setup>
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { useUserStore } from "@/store/user";
dayjs.locale("zh-cn");
const { store } = useUserStore();
document.title = store.compname || store.empname;
</script>
<style lang="less">
.ant-tree-node-content-wrapper {
  flex: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
  overflow: hidden;
}
</style>
