import { defineStore } from "pinia";
import { reactive } from "vue";

const transformLoginInfo = JSON.parse(
  localStorage.getItem("WJJtransformLoginInfo") || "{}"
);

export const useUserStore = defineStore("user", () => {
  const store = reactive({
    user: {},
    loginLoading: false,
    compid: "01",
    compname: "砼鑫商砼",
    empname: "JACK",
    opId: "666",
    ...transformLoginInfo,
  });

  const transformLogin = (info) => {
    const { compid, compname, empname, opId } = info;
    store.compid = compid || "01";
    store.compname = compname || "砼鑫商砼";
    store.empname = empname || "JACK";
    store.opId = opId || "666";
    localStorage.setItem("WJJtransformLoginInfo", JSON.stringify(info));
  };

  const loginOut = () => {
    localStorage.removeItem("WJJtransformLoginInfo");
    store.compid = "";
    store.compname = "";
    store.empname = "";
    store.opId = "";
    return Promise.resolve();
  };

  return { store, transformLogin, loginOut };
});
