export default [
  {
    path: "/index",
    component: () => import("@/Layout/index.vue"),
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "Index",
        component: () => import("@views/Index/index.vue"),
      },
    ],
  },
];
