/**
 * @Auther: 杨鹏飞
 * @Date: 2022/8/26 -08 - 26 -10:31 上午
 * @Description:
 */

const files = require.context("./modules", false, /\.js$/);
const modules = [];
files.keys().forEach((key) => {
  const child = files(key).default;
  modules.push(...child);
});

export default [
  ...modules,
  {
    path: "/transform",
    component: "",
  },
  {
    path: "/nopage",
    component: "<h1>404</h1>",
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/nopage",
    hidden: true,
  },
];
