// 菜单
export default [
  {
    path: "/Admixtures",
    component: () => import("@/Layout/index.vue"),
    children: [
      {
        path: "Brand",
        name: "厂家管理",
        component: () => import("@/views/Admixtures/Brand/index.vue"),
      },
      {
        path: "Drive",
        name: "设备管理",
        component: () => import("@/views/Admixtures/Drive/index.vue"),
      },
      {
        path: "Live",
        name: "设备列表",
        component: () => import("@/views/Admixtures/Live/index.vue"),
      },
    ],
  },
];
