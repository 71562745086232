import { createApp } from "vue";
import App from "./App.vue";
import Antd from "ant-design-vue";
import * as antIcons from "@ant-design/icons-vue";
import router from "./router";
import "./styles/custom.css";
import "./styles/dark.css";
import "./styles/index.less";
import { createPinia } from "pinia";
import TxTable from "@/components/TxTable/index.vue";
import TxConfirmButton from "@/components/TxConfirmButton/index.vue";
const app = createApp(App);
app.use(Antd).use(createPinia()).use(router).mount("#app");
app.component("TxTable", TxTable);
app.component("TxConfirmButton", TxConfirmButton);
for (const i in antIcons) {
  app.component(i, antIcons[i]);
}
app.config.globalProperties.$antIcons = antIcons;
