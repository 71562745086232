import { createRouter, createWebHistory } from "vue-router";
import routes from "./config";

import { useUserStore } from "@/store/user";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const LOGIN_IN_PATH = ["/transform"];
const ROOT_PATH = "/Admixtures/Brand";
const LOGIN_PAGE_PATH = "/login";

router.beforeEach((to) => {
  // 返回 false 以取消导航
  const { path, query } = to;

  if (path === LOGIN_PAGE_PATH) return true;

  const { store, transformLogin } = useUserStore();
  // 如果 是 转入登录
  if (LOGIN_IN_PATH.includes(path)) {
    // 如果载荷没有token
    if (!(query.compid && query.compname && query.empname && query.opId)) {
      // 本地存在token
      if (store.compid && store.compname && store.empname && store.opId) {
        // 跳转管理首页
        router.replace({ path: ROOT_PATH, qyert: {} });
      } else {
        // 否则 跳转到登录提示页
        router.replace({ path: LOGIN_PAGE_PATH, qyert: {} });
      }
    } else {
      // 载荷有token, 记录相关载荷信息
      transformLogin(query);
      // 更新页面标题
      document.title = store.empname || store.compname;
      // 跳转管理首页
      router.replace({ path: ROOT_PATH, qyert: {} });
    }
    // 本次路由事件不继续处理
    return false;
  }

  // 如果本地不存在 token
  if (!(store.compid && store.compname && store.empname && store.opId)) {
    // 跳转到登录提示页
    router.replace({ path: LOGIN_PAGE_PATH });
    return false;
  }

  // 正常情况 放行
  return true;
});

export default router;

export const toLogin = () => {
  router.replace({ path: LOGIN_PAGE_PATH });
};
